import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30')
];

export const server_loads = [0];

export const dictionary = {
		"/": [3],
		"/auth/error": [4,[2]],
		"/auth/invite/confirm": [5,[2]],
		"/auth/reset-password": [6,[2]],
		"/auth/reset-password/confirm": [7,[2]],
		"/auth/sign-in": [8,[2]],
		"/auth/sign-up": [9,[2]],
		"/auth/sign-up/confirm": [10,[2]],
		"/clients": [11],
		"/clients/add": [14],
		"/clients/[client_id=uuid]": [12],
		"/clients/[client_id=uuid]/edit": [13],
		"/notifications": [15],
		"/organisations": [24],
		"/organisations/[organisation_id=uuid]": [25],
		"/organisation": [16],
		"/organisation/roles": [17],
		"/organisation/roles/add": [20],
		"/organisation/roles/[role_id=uuid]": [18],
		"/organisation/roles/[role_id=uuid]/edit": [19],
		"/organisation/users": [21],
		"/organisation/users/invite": [23],
		"/organisation/users/[user_id=uuid]": [22],
		"/settings": [26],
		"/settings/change-password": [27],
		"/tasks": [28],
		"/tasks/new": [30],
		"/tasks/[task_id=uuid]": [29]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';