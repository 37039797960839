import * as Sentry from '@sentry/sveltekit'
import type { HandleClientError } from '@sveltejs/kit'

import {
	PUBLIC_SENTRY_DSN,
	PUBLIC_SENTRY_ENABLED,
	PUBLIC_SENTRY_ENVIRONMENT,
} from '$env/static/public'
import { ResponseError } from '$lib/client/runtime'

Sentry.init({
	enabled: PUBLIC_SENTRY_ENABLED === 'true',
	dsn: PUBLIC_SENTRY_DSN,
	environment: PUBLIC_SENTRY_ENVIRONMENT,

	// NOTE: This value should be reduced in production as user numbers increase.
	tracesSampleRate: 1.0,
})

const errorHandler: HandleClientError = async ({ error, status, message }) => {
	if (error instanceof ResponseError) {
		status = error.response.status
		message = error.response.statusText
	}

	return {
		status,
		message,
	}
}

export const handleError = Sentry.handleErrorWithSentry(errorHandler)
